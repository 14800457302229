<template>
  <div>
    <div class="category-box winAndplayCategory boardGame">
      <div v-if="loading" class="overlay-loader">
        <div class="loader-spin">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
      <div class="catgory_head">
        <div class="title">
          <h4>
            <img src="/static/images/dice_ic .svg" class="titile_icon square" />
            {{ $t("boardGames") }}
          </h4>
        </div>
        <!-- <div class="categorytab">
          <img src="/static/images/right_arrow_ic.svg" />
        </div>-->
      </div>

      <ul>
        <li>
          <div class="gamegrid" @click="goToTicTacToe()">
            <div class="gameImgbox">
              <img src="../assets/img/tictactoe.jpeg" />
              <div class="title-block">
                <div class="text-title">Tic Tac Toe</div>
                <span class="palyer-counter">Play and Win</span>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="gamegrid" @click="gotoLudo()">
            <div class="gameImgbox">
              <img src="/static/images/ludo.jpg" />
              <div class="title-block">
                <div class="text-title">Ludo</div>
                <span class="palyer-counter">Roll and Win</span>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="gamegrid" @click="gotoSnL()">
            <div class="gameImgbox">
              <img src="/static/images/snakes.jpg" />
              <div class="title-block">
                <div class="text-title">Snake And Ladder</div>
                <span class="palyer-counter">Roll and Win</span>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div class="gamegrid" @click="gotoChess()">
            <div class="gameImgbox">
              <img src="../assets/img/chess.jpg" />
              <div class="title-block">
                <div class="text-title">Chess</div>
                <span class="palyer-counter">Think and Win</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "WinAndPlayCategory",
  props: {},
  data() {
    return {
      spinnerLoading: false,
      xauthid: null,
      loading: false
    };
  },

  computed: {},

  methods: {
    goToTicTacToe() {
      this.loading = true;
      const __x = this.$cookie.get("X-Auth-Id");
      location.href = `https://tictactoe.services-money91.com/#/?xauthid=${__x}`;
    },
    gotoLudo() {
      this.loading = true;
      const __x = this.$cookie.get("X-Auth-Id");
      location.href = `https://board.services-money91.com/#/ludo?xauthid=${__x}`;
    },
    gotoSnL() {
      this.loading = true;
      const __x = this.$cookie.get("X-Auth-Id");
      location.href = `https://board.services-money91.com/#/snake-start?xauthid=${__x}`;
    },
    gotoChess() {
      this.loading = true;
      const __x = this.$cookie.get("X-Auth-Id");
      location.href = `https://board.services-money91.com/#/chesshome?xauthid=${__x}`;
    }
  }
};
</script>
<style></style>
